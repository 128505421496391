import { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { configSlider } from '../config';
import { useDispatch } from 'react-redux';
import Data from '../../components/data/data';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
const lupa = require('../../Assets/iconos/lupa.svg').default;
export const Typologies = props => {
	console.log('flats',props.data)
	const dispatch = useDispatch();
	const slider = useRef();
	const [allCards, setAllCards] = useState(props.data);
	const [selectCard, setSelectCard] = useState({});
	const openCalculator = () => {};

	const activeCard = card => {
		setSelectCard(card);
	};

	useEffect(() => {
		setAllCards(props.data);
		setSelectCard(props.data[0]);
	}, [props.data,allCards]);

	return (
		<div className='content-result'>
			<div className='absolute hidden'>
				{allCards.map((item,idx) => {
					return <img className='typo' src={item?.imagen?.url} alt='lupa' key={'typo'+idx}></img>;
				})}
			</div>
			{
				props.data.length === 0 ? (
					<div className='flex items-center justify-center text-center'>
						Lo sentimos, ya no nos quedan departamentos con esas características.
					</div>
				): (
					<div className='content-view'>
						<div className='flex flex_idx'>
							<div className='content-img'>
								<div className='_exp' href={selectCard?.imagen?.url}>
									<img className='' src={selectCard?.imagen?.url} alt={selectCard?.name}></img>
									<LightgalleryProvider group='any' key={'zoom'+selectCard?.name}>
										<LightgalleryItem group='any' src={selectCard?.imagen?.url}>
											<img className='lupa' src={lupa} alt='lupa'></img>
										</LightgalleryItem>
									</LightgalleryProvider>
								</div>
							</div>
							<div className='content-info'>
								<div className='row row-1'>
									<div className='tipologia type-tipo'>
										<span className='info-title'>Tipología</span>
										<span className='sub-title'>{selectCard?.name}</span>
									</div>
									{selectCard?.dormitorio ? (
										<div className='area type-tipo'>
											<span className='info-title'>N° de dorms</span>
											<span className='sub-title'>{selectCard?.dormitorio ? selectCard?.dormitorio : null}</span>
										</div>
									) : null}
								</div>
								<div className='desktop flex-col'>
									<Buttons page={props.page} showQuoter={props.showQuoter} selectCard={selectCard} showModal={props.showModal}></Buttons>
								</div>
							</div>
						</div>

						<div className='content-data'>
							<div className='content-selection'>
								<div className='content-slider'>
									<Slider {...configSlider} ref={slider}>
										{allCards.map(item => {
											return (
												<div
													className={'card ' + item.id}
													key={item.name}
													onClick={e => {
														activeCard(item);
														// dispatch(updateSelected(item));
													}}
												>
													<div className='content-card'>
														<img src={item.imagen.url}></img>
														<div className='content-title'>
															<span className='card-title'>{item.name}</span>
															<span className='card-subtitle'>{item.area_total} m²</span>
														</div>
													</div>
												</div>
											);
										})}
									</Slider>
								</div>
								<div
									className='btn-next'
									onClick={e => {
										slider.current.slickNext();
									}}
								></div>
								<div
									className='btn-prev'
									onClick={e => {
										slider.current.slickPrev();
									}}
								></div>
							</div>
						</div>
						<div className='mobile w-full flex-row'>
							<Buttons page={props.page} selectCard={selectCard} showQuoter={props.showQuoter} showModal={props.showModal}></Buttons>
						</div>
					</div>) 
			}
		</div>
	);
};

export const Buttons = ({ selectCard, page, showQuoter,showModal }) => {
	const props = useContext(Data);
	const { openCalculator } = props;
	const event = ()=>{
		showQuoter(selectCard);
	}
	useEffect(() => {
		
	},[selectCard])
	return (
		<>
			<div className='row element-top'>
				<div
					className='btn-radio'
					onClick={e => {
						openCalculator('open', selectCard, page);
					}}
				>
					Calcular cuota
				</div>
			</div>
			<div className='row'>
				<div
					className='btn-radio active'
					onClick={()=>{event()}}
				>
					Cotizar
				</div>
			</div>
		</>
	);
};
