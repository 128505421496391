import React, { Component } from 'react'
import Contacto from '../../components/contacto/contacto'
import ContactoPromo from '../../components/contacto/contactoPromo'
import './terminos.scss'
import FormularioReferidos from '../../components/contacto/formularioReferidos'
import { validateYupSchema } from 'formik'
import * as Yup from 'yup'
import validate, { FormContainer, setInputProps } from '../../components/common/forms/Form'
const emailSchema =() => Yup.object().shape({
    email: Yup.string()
      .email('Debe ser un correo electrónico válido')
      .required('El correo electrónico es obligatorio'),
  });
export default class Referidos extends Component {
    constructor(props){
        super(props)
        this.inputLink = React.createRef()
        this.inputEmail = React.createRef()
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    submitForm = (email) => {
        console.log(email)
    }
    clipBoard = (e) => {
        console.log(this.inputLink.current.value)
        navigator.clipboard.writeText(this.inputLink.current.value);
    }
    close=()=>{
        try {
            let btn = document.querySelector('.modal-referido')
            btn.classList.remove('active')
        } catch (error) {
            
        }
    }
    render() {
        return (
        <div className="page-terminos">
            <div className='modal-referido'>
                <div className='modal'>
                    <div className='icon-close mask' onClick={()=>{this.close()}}>
                    </div>
                    <span className='text-letter text-title-1-8 text-center mb-3'>Ahora puedes compartir el enlace con tus familiares y amigos</span>
                    <div className='mb-2'>
                        <span className='text-letter'>Comparte por tus redes sociales favoritas</span>
                        <div className='flex gap-1'>
                            <a href="https://api.whatsapp.com/send?text=Mira%20los%20proyectos%20que%20tenemos%20para%20ti:%20https://casaideal.com.pe/proyectos-en-venta" target="_blank" className='rounded-full bg-white w-5 h-5 flex items-center justify-center bg-black' style={{backgroundColor:'#25D366'}}>
                                <div className='icon-whatsapp w-4 h-3 bg-white'>
                                </div>
                            </a>
                            <a href="https://m.me/casaideal.com.pe?text=Mira%20los%20proyectos%20que%20tenemos%20para%20ti:%20https://casaideal.com.pe/proyectos-en-venta" target="_blank" className='rounded-full bg-white w-5 h-5 flex items-center justify-center bg-black' style={{backgroundColor:'#00B2FF'}}>
                            <div className='icon-messeger w-4 h-3 bg-white'>
                                </div>
                            </a>

                        </div>
                    </div>
                    <div className='mb-2'>
                        <span className='text-letter-80'>
                            O copia tu enlace y envía a tus amigos:
                        </span>
                        <div className='relative'>
                            <input className='input-style h-5 w-full pr-4' type='text' value='https://casaideal.com.pe/proyectos-en-venta' ref={this.inputLink}></input>
                            <div className='icon-copy w-3 h-3 bg-black absolute right-0 top-0 bottom-0 my-auto mr-1 cursor-pointer' onClick={(e)=>{this.clipBoard(e)}}>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <section>
                <div className="SliderDepartamentos">
                    <div className='background d-flex'>
                        <picture>
                            <source className="background " media="(min-width: 740px)" srcset={this.props.data.img}></source> 
                            <img className='background' src={this.props.data.imgMovil}></img>
                        </picture>
                    </div>
                    <span className="title-background" dangerouslySetInnerHTML={{__html:this.props.data.name}}></span>
                    
                </div>
            </section>
            <section className="information container">
            
                <div className="info">
                    <span className="handing-over bold">Descripción</span>
                    <div className="content-paragraph">
                        <p className='paragraph'>
                            El Beneficio “Campaña de Referidos” es otorgado a los clientes que deseen referirnos a un familiar o amigo para la compra de un departamento en un proyecto desarrollado por Casaideal Inmobiliaria. 
                        </p>
                        <p className='paragraph'>
                            Bajo este beneficio, se reconoce el regalo de una Giftcard de hasta S/3,000. Este beneficio será realizado bajo condiciones de cada proyecto.
                        </p>

                    </div>
                    <a href={this.props.data.pdf} target="_blank" className="btn-download outline-gray">
                        <div className="icon-button">
                            <img className="icon-download" src={require("../../Assets/iconos/icon-download.svg").default}></img>
                        </div>
                        <div className="text-button">
                            Términos y condiciones
                            <span className="peso">{this.props.data.kb} kb</span>
                        </div>
                    </a>
                    {
                        this.props.kitStandar ?
                    <div className="content-all">
                    
                        <div className="content-list">
                            <h2 className="title-list">Kit de Domótica Standard</h2>
                            <div className="table">
                                <div className="header-table">
                                    <div className="name-product">Producto</div>
                                    <div className="cant-product">Cantidad</div>
                                </div>
                                <div className="body-table">
                                    {
                                        this.props.kitStandar.map((e)=>{
                                            return(
                                                <div className="line-product">
                                                <div className="name-product"><img className="img-product" src={e.img}></img>{e.text}</div>
                                                <div className="cant-product">{e.cant}</div>
                                            </div> 
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="content-list content-list-2">
                            <h2 className="title-list title-list-2">Kit de domótica Premium</h2>
                            <div className="table">
                                <div className="header-table">
                                    <div className="name-product">Producto</div>
                                    <div className="cant-product">Cantidad</div>
                                </div>
                                <div className="body-table">
                                    {
                                        this.props.productPremiun.map((e)=>{
                                            return(
                                                <div className="line-product">
                                                <div className="name-product"><img className="img-product" src={e.img}></img>{e.text}</div>
                                                <div className="cant-product">{e.cant}</div>
                                            </div> 
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : null
                    }
                    
                </div>
                <div className="form" id="formulario">
                    <FormularioReferidos 
                        page={"benefits"}
                        promo={this.props.data.name}
                        options={this.props.option}
                    ></FormularioReferidos>
                </div>
            </section>
        </div>
        )
    }
}
