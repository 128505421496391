import React, { Component } from 'react'
import { Modal } from 'antd';
import axios from "axios"
import 'antd/dist/antd.css';
import Swal from "sweetalert2"
import { properties } from "../../properties.js"
import { FormContainer } from '../common/forms/Form.js';
import { BudgetValidatonSchema } from '../common/forms/constraints/ValidatonSchemas';
import { SperantService } from '../common/Http/SperantService.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class QuoterModal extends Component {
    constructor(props) {
        super(props)
        this._sperant = new SperantService();
        this.properties = this.props
        this.submit = this.submit.bind(this)
    }
    state = {}
    setStateFromInput = (event) =>{
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }
    async submit(values, { isSubmitting, setSubmitting, resetForm }) {
            this.props.actionModalCancel()
            let sheet = ""
            if (this.props.page === "unbox") sheet = "Unbox"
            if (this.props.page === "town") sheet = "Town"
            if (this.props.page === "salaverry") sheet = "M-Salaverry"
            // if (this.props.page === "nou") sheet = "Nou"
            if (this.props.page === "trend") sheet = "Trend"
            if (this.props.page === "midway") sheet = "Midway"
            if (this.props.page === "unity") sheet = "Unity"
            if (this.props.page === "stay") sheet = "Stay"
            if (this.props.project === "print") sheet = "Print"

            if (this.props.page === "proximo") {
                if (window.location.pathname.includes("cercado")) {
                    sheet = "Cercado"
                } else {
                    sheet = "Barranco"
                }
            }

            let utms = {}
            new URLSearchParams(localStorage.getItem("url_query"))
                .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))
    
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
    
            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
                "range": `${sheet}!A:F`,
                "values": [[
                    date, values.fname, values.lname, values.telephone, values.email, values.rooms_amount, values.time_of_day,
                    utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
                ]],
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).catch(e=>console.error(e))

            try {
                const {data: {data: client}} = await this._sperant.createClient({
                    "fname": values.fname,
                    "lname": values.lname,
                    "email": values.email,
                    "phone": values.telephone,
                    "project_id": this.props.sperant_id,
                    "interest_type_id": 4,
                    "input_channel_id": 4,
                    ...utms
                })

                await this._sperant.createBudget(
                    client,
                    values.floor,
                    this.props.type_id,
                    this.props.template_id,
                    utms
                )

                this.props.history.push(`/gracias/cotizacion-${this.props.page}`)
                // Swal.fire({
                //     title: '',
                //     text: 'La cotización se envió a su correo',
                //     icon: 'success',
                //     confirmButtonText: 'Aceptar',
                //     confirmButtonColor: 'red'
                // })
                // resetForm({})
            } catch (error) {
                console.error(error)
                Swal.fire({
                    title: '',
                    text: '¡Error! intentelo más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            }
    }

    setInputProps(name, {errors, touched, handleChange, handleBlur}) {
        return {
            name: name,
            className: `${errors[name] && touched[name] && "invalid"}`,
            onChange: handleChange,
            onBlur: handleBlur
        }
    }
    render (){
        this.type_id = this.props.type_id
        const initialValues = () => {
            const props = this.props
            return {
                fname: "",
                lname: "",
                email: "",
                telephone: "", 
                type_id: this.type_id,
                project_id: this.props.sperant_id,
                template_id: props.template_id
            } 
        }
        return(
            <FormContainer initialValues={initialValues()} validationSchema={BudgetValidatonSchema} onSubmit={this.submit}>
                {form => {const {handleSubmit } = form;
                return(
                <Modal title="Cotizador" open={this.props.visible}
                    onOk={handleSubmit} onCancel={this.props.actionModalCancel} okText={"Cotizar"}
                    cancelText={"Cerrar"} okButtonProps={{disabled: form.isSubmitting}}
                    cancelButtonProps={{disabled: form.isSubmitting}} centered>
                    <p className="parrafo">Ingresa tus datos para enviarte la cotización a correo.</p>
                    <div className="content-input user-content">
                        <div className="icon user"></div>
                        <input type="text" placeholder="Nombre*" {...this.setInputProps("fname", form)} />
                    </div>
                    <div  className="content-input user-content">
                        <div className="icon user"></div>
                        <input type="text" placeholder="Apellido*" {...this.setInputProps("lname", form)}/>
                    </div>
                    <div  className="content-input">
                        <div className="icon mail"></div>
                        <input type="email" placeholder="Email*" {...this.setInputProps("email", form)}/>
                    </div>
                    <div  className="content-input">
                        <div className="icon phone"></div>
                        <input type="tel" placeholder="Teléfono*" {...this.setInputProps("telephone", form)}/>
                    </div>
                    {this.props?.value?.selected?.units &&
                    <div className="content-input long">
                        <div className="icon selected"></div>
                        <select type="tel" placeholder="Teléfono*" {...this.setInputProps("floor", form)}>
                            <option selected>Selecciona un piso</option>
                            {this.props?.value?.selected?.units.map((item)=>
                                <option value={item.id}>{item.name} - {item?.construction?.total_area} m²</option>
                            )}
                        </select>
                    </div>}
                </Modal>
                )}}
            </FormContainer>
        )
    }
}
const MapStateProps=(state)=>{
    return(
        {value:state.CalculatorStore}
    )
}

export default withRouter(connect(MapStateProps,null)(QuoterModal))