import React, { Component } from 'react'
import "../contactanos/contacto.scss"
import $ from 'jquery'
import Swal from 'sweetalert2'
import axios from 'axios'
import { SperantService } from '../common/Http/SperantService'
import { FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { ContactUsValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Data from '../data/data'
import { withRouter } from 'react-router-dom'
import { projects } from '../../data/projects'

class Contactanos extends Component {
    constructor() {
        super()
        this.sperantService = new SperantService()
        this.submitForm = this.submitForm.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.onError = this.onError.bind(this)
    }
    componentDidMount(){
        $(".link").removeClass("active")
        $("#contactanos").addClass("active");
        window.scrollTo(0,0)
    }
    onSuccess = _ =>{
        this.props.history.push("/gracias/contactanos")
        // Swal.fire({
        //     title: '',
        //     text: 'Muchas Gracias, un asesor te contactará pronto',
        //     icon: 'success',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: 'red'
        // })
    }
    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })
    }
    async submitForm(values, { setSubmitting, resetForm }) {
        let utms = {}
        new URLSearchParams(localStorage.getItem("url_query"))
            .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))

        // sincorreo@sincorreo.com Kevin aguilar
        if (values.email.toLowerCase().match("sincorreo")) {
            this.onSuccess()
            return
        }
            
        let sheet = ""
        if (values.project === "unbox") sheet = "Unbox"
        if (values.project === "town") sheet = "Town"
        if (values.project === "salaverry") sheet = "M-Salaverry"
        // if (values.project === "nou") sheet = "Nou"
        if (values.project === "trend") sheet = "Trend"
        if (values.project === "midway") sheet = "Midway"
        if (values.project === "unity") sheet = "Unity"
        if (values.project === "stay") sheet = "Stay"
        if (values.project === "print") sheet = "Print"

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        try {
            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
                "range": `${sheet}!A:F`,
                "values": [[
                    date, values.fname, values.lname, values.telephone, values.email, values.rooms_amount,
                    utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
                ]],
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
        } catch (error) {}

        let client = {
            "fname": values.fname,
            "lname": values.lname,
            "email": values.email,
            "phone": values.telephone,
            "project_id": projects.get(values.project).sperant_id,
            "interest_type_id": 4,
            "input_channel_id": 4,
            "observation": `Nro. Dorms: ${values.rooms_amount}`,
            ...utms
        }
        
        this.sperantService.createClient(client)
        .then(r=>{
            setSubmitting(false)
            window.fbq && window.fbq('track', 'Lead')
            this.onSuccess()
        })
        .catch(e=>{
            setSubmitting(false)
            this.onError()
        })
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        project: "",
        rooms_amount: "",
        terms: false
    }
    handleFormChange({values}) {
        // console.log(data.target.value)
        if (values.project) {            
            $('.dormitorio').removeAttr('disabled');
            $('.dormitorio').removeClass('dormitorio');
        }
        // if(values.project == 'nou'){
        //     $("#valor-1").text("1 dormitorio plus")
        //     $("#valor-2").text("2 dormitorios")
        //     $("#valor-3").text("2 dormitorios plus")
        // }
        if(values.project == 'salaverry'){
            $("#valor-1").text("1 dormitorio")
            $("#valor-2").text("2 dormitorios")
            $("#valor-3").text("3 dormitorios")
        }
        if(values.project == 'trend'){
            $("#valor-1").text("1 dormitorio")
            $("#valor-2").text("2 dormitorios")
            $("#valor-3").text("3 dormitorios")
        }
        if(values.project == 'town'){
            $("#valor-1").text("1 dormitorio")
            $("#valor-2").text("2 dormitorios")
            $("#valor-3").text("3 dormitorios")
        }
    }

    render() {
        return (
        <Data.Consumer>
            {context =>(
            <div className={"vende-tu-terreno "+this.props.page}>
                <h2 className="title"><span className='bold'>TE ASESORAMOS</span> VÍA ONLINE</h2>
                <p className="paragraph">Llena el formulario y un asesor se comuncará contigo.</p>
                <FormContainer
                initialValues={this.initialValues} validationSchema={ContactUsValidatonSchema} onSubmit={this.submitForm}>
                {form => {const {handleSubmit} = form;
                this.handleFormChange(form)
                return(
                <form className="formulario" onSubmit={handleSubmit}>
                    <input className="input col" placeholder="Nombre" {...setInputProps("fname", "input col", form)}></input>
                    <input className="input" placeholder="Apellido" {...setInputProps("lname", "input", form)}></input>
                    <input className="input col" placeholder="Teléfono" {...setInputProps("telephone", "input col", form)}></input>
                    <input className="input" placeholder="E-mail" {...setInputProps("email", "input", form)}></input>
                    <select {...setInputProps("project", "input col", form)}>
                        <option selected disabled>Escoge un proyecto</option>
                        {Array.from(projects.keys()).map((project, index) =>
                            <option key={index} value={project}>{project}</option>
                        )}
                    </select>
                    <select disabled {...setInputProps("rooms_amount", "input dormitorio", form)}>
                        <option selected disabled>Número de dormitorios</option>
                        <option id="valor-2" value ="2">2 dormitorios</option>
                        <option id="valor-3" value ="3">3 dormitorios</option>
                    </select>
                    <div className="flex-column button-radius">
                        <div className="radio flex-row">                            
                            <label className="button-title flex-row" for="terminos">
                            <input type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                            <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a  href="/politicas-de-privacidad" target="_blank" className="d-block terminos pointer">políticas de privacidad </a></label><br></br>
                        </div>
                    </div>
                    <div className="btn"><button type="submit" className="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                </form>)}}
            </FormContainer>
            </div>
            )}
            </Data.Consumer>
        )
    }
}

export default withRouter(Contactanos)