import React, { Component } from 'react'
import axios from "axios"
import Swal from 'sweetalert2'

import "./contacto.scss"
import { FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { SperantService } from '../common/Http/SperantService'
import { HomeContactValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Data from '../data/data'
import { withRouter } from 'react-router-dom'
import { projects } from '../../data/projects'
 class ContactoPromo extends Component {
    constructor(props) {
        super(props)
        this.sperantService = new SperantService()
        this.submitForm = this.submitForm.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.onError = this.onError.bind(this)
    }
    onSuccess = _ =>{
        this.props.history.push(`/gracias${window.location.pathname}`)
        // Swal.fire({
        //     title: '',
        //     text: 'Muchas gracias, un asesor te contactará pronto',
        //     icon: 'success',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: 'red'
        // })
    }
    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })
    }
    async submitForm(values, { setSubmitting, resetForm }) {

        // sincorreo@sincorreo.com Kevin aguilar
        if (values.email.toLowerCase().match("sincorreo")) {
            this.onSuccess()
            return
        }

        let utms = {}
        new URLSearchParams(localStorage.getItem("url_query"))
            .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))
        
        const promo = this.props.promo.replace( /(<([^>]+)>)/ig, '')

        let client = {
            "fname": values.fname,
            "lname": values.lname,
            "email": values.email,
            "phone": values.telephone,
            "project_id": projects.get(values.project).sperant_id,
            "interest_type_id": 4,
            "input_channel_id": 4,
            "observation": `Nro. Dorms: ${values.rooms_amount}, Promoción: ${promo}`,
            ...utms
        }


        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
            "range": `Beneficios!A:F`,
            "values": [[
                date, values.project, promo, values.fname, values.lname, values.telephone, values.email, values.rooms_amount, values.time_of_day,
                utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
            ]]
        })

        this.sperantService.createClient(client)
            .then(r=>{
                setSubmitting(false)
                window.fbq && window.fbq('track', 'Lead')
                this.onSuccess()
            })
            .catch(e=>{
                setSubmitting(false)
                this.onError()
            })
    }
    
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        project: "",
        rooms_available: [],
        rooms_amount: "",
        time_of_day: "",
        terms: false
    }

    render() {
        return (
            <Data.Consumer>
            {context =>(
            <FormContainer
                initialValues={this.initialValues} validationSchema={HomeContactValidatonSchema} onSubmit={this.submitForm}
            >
                {form => {const {values, handleSubmit} = form;
                return(
                <div className={"contacto "+this.props.page}>
                    <h2 className="title"><span className='bold'>TE ASESORAMOS</span> VÍA ONLINE</h2>
                    <p className="paragraph">Llena el formulario y un asesor se comuncará contigo.</p>
                    <form className="formulario" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Nombre*" {...setInputProps("fname", "input col", form)}></input>
                        <input type="text" placeholder="Apellido*" {...setInputProps("lname", "input", form)}></input>
                        <input type="email" placeholder="E-mail*" {...setInputProps("email", "input large", form)}></input>
                        <input type="tel" placeholder="Teléfono*" {...setInputProps("telephone", "input ", form)}></input>
                        <select {...setInputProps("project", "input col", form)}>
                            <option selected disabled value="1">Proyecto*</option>
                            {this.props?.options.map((project, index) =>
                                <option key={index} value={project.text}>{project.text}</option>
                            )}
                        </select>
                        <select {...setInputProps("rooms_amount", "input col", form)}>
                            <option selected disabled value ="1">Número de dormitorios</option>
                            {projects.get(values.project)?.form?.map((e)=>
                                <option value={e.title}>{e.title}</option>
                            )}
                        </select>
                        <input type="text" placeholder="Horario de contacto*" {...setInputProps("time_of_day", "input", form)}></input>
                        <div className="flex-column button-radius">
                            <div className="radio flex-row">
                                <input type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                <label className="button-title flex-row" for="terminos"></label>
                                <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a className="d-block terminos pointer" href="/politicas-de-privacidad" target="_blank"> políticas de privacidad</a><br></br>
                            </div>
                        </div>
                        <div className="btn"><button type="submit" className="btn-submit btn-radio" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                    </form>
                </div>)}}
            </FormContainer>
            )}
            </Data.Consumer>
        )
    }
}
export default withRouter(ContactoPromo)