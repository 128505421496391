import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple'
import React, { Component } from 'react'
import './flotante.scss'
export default class Flotante extends Component {
    constructor(props){
        super(props)
        this.moveTo = this.moveTo.bind(this)
        this.hide = this.hide.bind(this)
        this.offset = this.offset.bind(this);
        this.state = {
            phone: "",
            project:this.props.project,
        }
    }
    componentDidMount(){
        if(this.props.phone.length > 1){
            let random = Math.random()>=0.5
            this.setState({phone:random == true ? this.props.phone[0] : this.props.phone[1]})
        }else{
            this.setState({phone:this.props.phone[0] })
        }

        window.addEventListener('scroll',this.hide)
        
     
        // if(this.state.phone.length > 1){

        // }
    }
    hide =()=>{
      
    }
    moveTo(elementFrom,elementTo){
        let navHeight = document.querySelector("#nav").clientHeight
        window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight+30))
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
        }
    }
    render() {
        return (
            <div className="content-button-contact">
                {this.state.project && <div id="contact" className="whatsapp d-block" onClick={()=>this.moveTo("#contact","#formulario")}>
                    <img className='contact' src={require('../../Assets/iconos/contact.svg').default}></img>
                </div>}
                <a target="_blank" href={`https://wa.me/51${this.state.phone}?text=Hola, quiero más información ${this.state.project ? "sobre "+this.state.project : ""}`} className="whatsapp d-block">
                    <img className='' src={require('../../Assets/images/inicio/WhatsApp_Logo_1.png')}></img>
                </a>
            </div>
        )
    }
}