import React, { Component } from 'react'
import "./contacto.scss"
import { FormContainer, checkableBoolProps } from '../common/forms/Form'
import { SperantService } from '../common/Http/SperantService'
import { ContactValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Swal from 'sweetalert2'
import axios from "axios"
import Data from '../data/data'
import { withRouter } from 'react-router-dom'

class Contacto extends Component {
    constructor(props) {
        super(props)
        this.sperantService = new SperantService()
        this.submitForm = this.submitForm.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.onError = this.onError.bind(this)
    }
    onSuccess = _ =>{
        window.location.href = `/gracias/formulario-${this.props.project.page}`
        // Swal.fire({
        //     title: '',
        //     text: 'Muchas Gracias, un asesor te contactará pronto',
        //     icon: 'success',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: 'red'
        // })
    }
    onError = e =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })
    }
    async submitForm(values, { setSubmitting, resetForm }) {

        // sincorreo@sincorreo.com Kevin aguilar
        if (values.email.toLowerCase().match("sincorreo")) {
            this.onSuccess()
            return
        }

        let utms = {}
        new URLSearchParams(localStorage.getItem("url_query"))
            .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))
            
        let client = {
            "fname": values.fname,
            "lname": values.lname,
            "email": values.email,
            "phone": values.telephone,
            "project_id": this.props.project.sperant_id,
            "interest_type_id": 4,
            "input_channel_id": 4,
            "observation": `Nro. Dorms: ${values.rooms_amount}, Preferencia de contacto: ${values.time_of_day}`,
            ...utms
        }
        

        let sheet = ""
        let crm = this.props.project.page.includes("proximo") === false

        if (this.props.project.page === "unbox") sheet = "Unbox"
        // if (this.props.project.page === "town") sheet = "Town"
        if (this.props.project.page === "salaverry") sheet = "M-Salaverry"
        // if (this.props.project.page === "nou") sheet = "Nou"
        if (this.props.project.page === "trend") sheet = "Trend"
        if (this.props.project.page === "midway") sheet = "Midway"
        if (this.props.project.page === "unity") sheet = "Unity"
        if (this.props.project.page === "stay") sheet = "Stay"
        if (this.props.project.page === "print") sheet = "Print"

        if (!crm) {
            if (this.props.project.page.includes("magdalena")) sheet = "Magdalena"
            if (this.props.project.page.includes("santa-beatriz")) sheet = "Santa Beatriz"
        }

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        try {
            await axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
                "range": `${sheet}!A:F`,
                "values": [[
                    date, values.fname, values.lname, values.telephone, values.email, values.rooms_amount, values.time_of_day,
                    utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
                ]],
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })

            !crm && this.onSuccess()
        } catch (error) {
            !crm && this.onSuccess()
        }

        if(crm) {
            // console.log(values, client)
            this.sperantService.createClient(client)
                .then(r=>{
                    setSubmitting(false)
                    window.fbq && window.fbq('track', 'Lead')
                    this.onSuccess()
                })
                .catch(e=>{
                    setSubmitting(false)
                    this.onError(e)
                })
        }
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        rooms_amount: "",
        time_of_day: ""
    }
    setInputProps(name, classes = "", {errors, touched, handleChange, handleBlur}) {
        return {
            name: name,
            className: `${classes} ${errors[name] && touched[name] && "invalid"}`,
            onChange: handleChange,
            onBlur: handleBlur
        }
    }
    render() {
        return (
            <Data.Consumer>
            {context =>(
            <FormContainer
                initialValues={this.initialValues} validationSchema={ContactValidatonSchema} onSubmit={this.submitForm}
            >
                {form => {const {handleSubmit } = form;
                return(
                <div className={"contacto "+this.props.project.page}>
                    <h2 className="title"><span className='bold'>TE ASESORAMOS</span> VÍA ONLINE</h2>
                    <p className="paragraph">Llena el formulario y un asesor se comuncará contigo.</p>
                    <form className="formulario" onSubmit={handleSubmit}>
                        <input type="text" placeholder="Nombre*" {...this.setInputProps("fname", "input col", form)}></input>
                        <input type="text" placeholder="Apellido*" {...this.setInputProps("lname", "input", form)}></input>
                        <input type="tel" placeholder="Teléfono*" {...this.setInputProps("telephone", "input large", form)}></input>
                        <input type="email" placeholder="E-mail*" {...this.setInputProps("email", "input large", form)}></input>
                        <select {...this.setInputProps("rooms_amount", "input col", form)}>
                            <option selected disabled value ="1">Número de dormitorios</option>
                            {
                                this.props.form.map((e)=>{
                                    return(
                                        <option value ={e.value}>{e.title}</option>
                                    )
                                })
                            }
                        </select>
                        <input type="text" placeholder="Horario de contacto*" {...this.setInputProps("time_of_day", "input", form)}></input>
                        <div className="flex-column button-radius">
                            <div className="radio flex-row">
                                <input type="checkbox" id="terminos-y-condiciones" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                <label className="button-title flex-row" for="terminos-y-condiciones"></label>
                                <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a href="/politicas-de-privacidad" target="_blank" className="d-block terminos pointer"> políticas de privacidad</a><br></br>
                            </div>
                        </div>
                        <div className="btn"><button type="submit" className="btn-submit btn-radio" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                    </form>
                </div>)}}
            </FormContainer>
             )}
             </Data.Consumer>
        )
    }
}
export default withRouter(Contacto)

