let midway = {
    sperant_id: 21,
    route:"/midway",
    page:"midway",
    district:'Magdalena',
    whatsapp:["968206497","936830642"],
    titlehead: "Proyecto Stay | Departamentos en Jesús María",
	metadescription:"Vive en la mejor zona de Magdalena, límite con San Isidro, en Av. Pershing 162. Departamentos de 1, 2 y 3 dorms desde S/323,500. Encuentra en Midway la opción ideal para disfrutar de tu propio espacio y acompañado de tu familia con excelentes áreas sociales. Cotiza aquí.",
	metakeywords:"departamentos en magdalena del mar, departamentos en venta en magdalena, inmobiliaria, magdalena del mar, casaideal inmobiliaria, departamentos límite con san Isidro, departamentos de 1, 2 y 3 dormitorios, edificio en construcción, departamentos en construcción",
    iziPubKey: {
        prod: "68985624:publickey_Eapp5w12r3PSsillp6dax6trRTaoH8I380S0wx12TLQfF",
        dev: "68985624:testpublickey_2OVV3DzDPbjC4im1VdHQrUPaVzSs2nGYGxEATpHWylqzn",
    },
    areas:{
        cant:4,
        comunes:[
        ],
        interiores:[
        ],
        exteriores:{
            cantButton:"recorrido-none",
            title:"Avance de obra Agosto",
            desktop:[
            ]
        },
        avance:{
            cantButton:"avance-none",
            title:"Avance de obra Septiembre",
            desktop:[
            ],
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[
              
            ]
        },
        depa_piloto:[
         
        ],
        recorrido:"https://kuula.co/share/collection/7Yk8N?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1",
    },
    form:[
        {value:1,title:"1 Dormitorio"},
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"TU NUEVO ",subtitle:"HOGAR",
        entrega:'Entrega Inmediata',
        entregamovil:'En Construcción',
        frase:"EN MAGDALENA DEL MAR LÍMITE CON <span class='bold'>SAN ISIDRO</span>",
        pisos:"20",depas:"118",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Pershing N° 162, Magdalena del Mar",precio:"S/323,500",desde:"41m²",hasta:"77m²",
        fachada:require("../../Assets/images/fachada/Slide/MIDWAY.jpg"),
        fachadaMinified:require("../../Assets/images/fachada/Slide/resize/resize-MIDWAY.jpg"),
        // ingreso:require("../../Assets/images/fachada/midway/fachada-midway-noche.png"),
        parrafo:'<p class="paragraph paragraph-1">Midway se encuentra a tan solo unos pasos de parques, universidades, centros comerciales y las principales vías de acceso. Nuestro proyecto ha sido diseñado bajo una propuesta que busca transmitir calidez y confort a sus usuarios. </p><p class="paragraph paragraph-2">Encuentra en Midway la opción ideal para disfrutar de tu propio espacio y acompañado de tu familia con excelentes áreas sociales.</p>',
        parrafoArquitectura1:'Departamentos para vivir cerca a las cosas que te gustan. Cafés, restaurantes, supermercados, parques, etc.',
        brochure: 'https://casaideal.com.pe/midway/brochure-midway.html',
        brochure_mobile: 'https://casaideal.com.pe/midway/mobile/brochure-midway.html',
        arquitectura:'<span class="bold">ARQUITECTURA </span>Y DISEÑO',
        mapa:require("../../Assets/mapas/mapa-midway.jpg"),
        class:"",  
        sitios:[
            {title:"Centros de Salud",sitio:["Hospital Militar","Centro Médico Jesús María","Policlínico Peruano Japonés","Medicis","Centro de Salud Magdalena","MedSolutions"]},
            {title:"Colegios y Centros de estudio",sitio:["Colegio San Felipe","Isil","Euroidiomas","Bibliotea Municipal","Universidad Jaime Bausate y Meza","Colegio de Jesú","Colegio Newton","Colegio Retama","Pamer","CEPRE UNI","Toulouse Lautrec"]},
            {title:"Supermercados y centros comenrciales",sitio:["Metro","Centro Comercial San Felipe","Real Plaza Salaverry","Vivanda"]},
        ],
    },
    slideDepa:[
    ],
    atributos:[
        
        {icon:"camara",text:"<strong>Cámaras de seguridad</strong></br> en áreas comunes"},
        {icon:"lobby",text:"Lobby de </br><strong>doble altura</strong>"},
        {icon:"sala-coworking",text:"<strong>Zona coworking</strong>"},
        {icon:"game",text:"<strong>Game room</strong>"},
        {icon:"gym",text:"<strong>Gym</strong>"},
        {icon:"parrilla",text:"<strong>Zona BBQ</strong>"},
        {icon:"jardin",text:"<strong>Terraza Jardín</strong>"},
        {icon:"area-child",text:"<strong>Zona de niños</strong>"},
        {icon:"pet",text:"<strong>Petshower</strong>"},
        {icon:"lavado",text:"<strong>Zona de lavado</strong>"},
        {icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
        {icon:"none",text:""},
    ],
    tipologias:{
        page:"midway",
        template_id: 91,
        buttons:[{text:"01",value:1},{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/proyectos/midway.png'),
        cards: [
        ],
    },
}

export default midway
