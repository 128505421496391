import React, { Component } from 'react';
import { connect } from 'react-redux';
import './shop.scss';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import { addCard, deleteCard, openCart, ShowCard, updateUnits, openMessageSelect } from '../../data/carts';
import { modified } from '../../data/projects/compactProjects';
import $, { type } from 'jquery';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SperantService } from '../../components/common/Http/SperantService';
import { ProjectHandler } from './ProjectHandler';
import { ProductSkeletons } from '../../components/skeletons/ProductSkeletons';
import { FormContainer, setInputProps } from '../../components/common/forms/Form';
import { filterSchema } from './schema';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';

class Shop extends Component {
	constructor(props) {
		super(props);
		this.sperantService = new SperantService();
		this.projectHandler = new ProjectHandler();
		this.openFilter = this.openFilter.bind(this);
		this.state = {
			image: false,
			filterOpen: false,
			heightFilter: '3.4rem',
			percent: 10,
			step: 1,
			result: {},
			currentSlide: 0,
			projects: [],
			constantProjects: [],

			typesSlider: {
				dots: false,
				arrows: false,
				infinite: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				easing: 'linear',
				centerPadding: '10px',
				afterChange: event => {
					let position = event !== 0 ? 1 + event : 0;
					position = this.props.dataProjects.projects.length - 0.2 === position ? this.props.dataProjects.projects.length : position;
					this.setState({ currentSlide: position || 1, percent: (position / this.props.dataProjects.projects.length) * 100 });
				},
			},
		};
	}
	eventHandler = data => {
		console.log(data.projects);
		this.setState({ projects: data.projects });
	};
	checked = card => {
		let check = this.props.cart.tipologiesSelected
			.map(e => {
				return e.id;
			})
			.includes(card.id);

		return check;
	};
	componentDidUpdate() {}
	async componentDidMount() {
		let savedProjects = JSON.parse(sessionStorage.getItem('projects_shop'));
		if (!savedProjects) {
			const projects = await this.projectHandler.processProjects(this.props.dataProjects.projects);
			this.props.modified(projects);
			this.setState({ projects: projects });
			this.setState({ constantProjects: projects });
		}
	}
	submitFilter(e) {
		e.preventDefault();
	}

	formatCurrency = value =>
		value &&
		!isNaN(value.toString().replace(/,/g, '')) &&
		parseFloat(value.toString().replace(/,/g, ''))
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	openFilter() {
		let heightFilter = document.querySelector('.form-filter').getBoundingClientRect().height;

		this.setState({
			filterOpen: !this.state.filterOpen,
			heightFilter: this.state.filterOpen == false ? heightFilter + 54 + 'px' : '54px',
		});
	}
	toggleShowCard = value => {
		let toggle = JSON.parse(JSON.stringify(this.props.cart.toggleShowCard));
		if (toggle.includes(value)) {
			const index = toggle.indexOf(value);
			toggle.splice(index, 1);
		} else {
			toggle.push(value);
		}

		this.props.ShowCard(toggle);
	};

	render() {
		return (
			<main className='store'>
				<section>
					<div className='SliderDepartamentos'>
						<div className='background d-flex'>
							<picture>
								<source className='background ' media='(min-width: 740px)' srcset={this.props.value.shop.header}></source>
								<img className='background' src={this.props.value.shop.header}></img>
							</picture>
						</div>
					</div>
				</section>
				<section className='Filter'>
					<div className={`content-filter container-shop ${this.state.filterOpen == true ? 'active' : ''}`} style={{ height: this.state.heightFilter }}>
						<div className='title-filter'>
							<span className='bold'>Añadir Filtros </span>
							<div
								className={`mask ${this.state.filterOpen == true ? 'less' : 'more'}`}
								onClick={() => {
									this.openFilter();
								}}
							></div>
						</div>
						<FilterOptions constantProjects={this.state.constantProjects} event={this.eventHandler}></FilterOptions>
					</div>
				</section>
				<section className='All-projects'>
					<div className='container-shop'>
						{!this.state.constantProjects.length ? <ProductSkeletons number={2} cards={5} /> : null}
						{this.state.projects.length ? (
							this.state.projects.map((item, index) => {
								if (item.typologies.length == 0) {
									return null;
								}
								return (
									<div className='content-project' style={{ zIndex: 50 - index }} key={index + 'content-project'}>
										<div className='reference-project'>
											<div className='content-text'>
												<span className='text'>
													PROYECTO <strong>{item.project_name}</strong>
												</span>
												<span className='radius b-secondary semi-bold-poppins c-white uppercase'>{item.district_project}</span>
											</div>
											<div className='content-img'>
												<img className='' src={item?.fachadaminified?.url}></img>
											</div>
										</div>
										<div className='targets'>
											<div className='resume'>
												<div
													className='radius b-white c-black shadow'
													onClick={() => {
														this.toggleShowCard(item.project_name);
													}}
												>
													{`${this.props.cart.toggleShowCard.includes(item.page) ? 'Mostrar más' : 'Ocultar'}`}
													<img className={`${this.props.cart.toggleShowCard.includes(item.page) ? 'close-card' : ''}`} src={require('../../Assets/images/iconos/signal-down.svg').default}></img>
												</div>
											</div>
											<div className='content-cards'>
												<MediaQuery query='(min-width: 500px)'>
													{item.typologies.map((card, index) => {
														return <CardStoreComponent card={card} active={this.state.toggleShowCard} project={item} checked={this.checked} index={index} key={index + 'content-card-store'} />;
													})}
												</MediaQuery>
												<MediaQuery query='(max-width: 499px)'>
													<SliderStoreComponent item={item} active={this.state.toggleShowCard} project={item} />
												</MediaQuery>
											</div>
										</div>
									</div>
								);
							})
						) : (
							<span className='span-result'>No se encontraros resultados para esta busqueda</span>
						)}
					</div>
				</section>
			</main>
		);
	}
}

const FilterOptions = ({ constantProjects = [], event }) => {
	const initialValues = {
		district: 'all',
		vista: 'all',
		dorms: 'all',
		project: 'all',
		range_price: 'all',
	};
	const rangeData = [
		{ value: [200000, 299999], title: 'Desde S/200,000 hasta S/299,999' },
		{ value: [300000, 399999], title: 'Desde S/300,000 hasta S/399,999' },
		{ value: [400000, 499999], title: 'Desde S/400,000 hasta S/499,999' },
		{ value: [500000, 599999], title: 'Desde S/500,000 hasta S/599,999' },
		{ value: [600000, 699999], title: 'Desde S/600,000 hasta S/699,999' },
		{ value: [700000, 799999], title: 'Desde S/700,000 hasta S/799,999' },
		{ value: [800000, 899999], title: 'Desde S/800,000 hasta S/899,999' },
		{ value: [900000, 999999], title: 'Desde S/900,000 hasta S/999,999' },
		{ value: [1000000, 1099999], title: 'Desde S/1,000,000 hasta S/1,099,999' },
	];

	const capitalize = str => {
		if (!str) {
			return '';
		}
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	const combinedFilters = ({ array, data }) => {
		let result = data || [];

		array.forEach((item, index) => {
			result = item.fn(result, item.parameter);
		});
		return result;
	};
	const filterDorms = (data, dorm) => {
		if (dorm == 'all' || dorm == '') return data;
		let result = data.filter(item => {
			return item.dormitorio == dorm;
		});
		return result;
	};
	const filterVista = (data, vista) => {
		if (vista === 'all' || vista === '') return data;
		console.log(data, vista);
		let result = data.filter(item => {
			return item.vista == vista;
		});
		return result;
	};
	const filterRangePrice = (data, price) => {
		if (price === 'all' || price === '') return data;
		let splitPrice = price.toString().split(',');
		let result = data.filter(item => {
			return item.min_price >= parseInt(splitPrice[0]) && item.min_price <= parseInt(splitPrice[1]);
		});
		// console.log(data)
		return result;
	};

	const getProjectsFiltered = (data, values) => {
		return data.map(e => {
			let newData = combinedFilters({
				array: [
					{ fn: filterDorms, parameter: values.dorms },
					{ fn: filterVista, parameter: values.vista },
					{ fn: filterRangePrice, parameter: values.range_price },
				],
				data: e.typologies,
			});
			return { ...e, typologies: newData };
		});
	};
	const onSubmit = values => {
		let allProjects = constantProjects;
		allProjects =
			values.project == 'all'
				? allProjects
				: allProjects.filter(item => {
						return item.project_name == values.project;
					});
		allProjects =
			values.district == 'all'
				? allProjects
				: allProjects.filter(item => {
						return item.district_project.toLowerCase() == values.district.toLowerCase();
					});
		allProjects = getProjectsFiltered(allProjects, values);
		event({ projects: allProjects });
	};
	return (
		<FormContainer initialValues={initialValues} validationSchema={filterSchema} onSubmit={onSubmit}>
			{form => {
				const { handleSubmit } = form;
				return (
					<form className='form-filter' onSubmit={handleSubmit}>
						<div className='content-inputs'>
							<div className='content-input'>
								<span className='title'>Proyectos</span>
								<select {...setInputProps('project', 'select proyectos', form)}>
									<option value='all' selected>
										Todos
									</option>
									{constantProjects.map((item, index) => {
										return (
											<option value={item.project_name} key={'select' + index}>
												{capitalize(item.project_name)}
											</option>
										);
									})}
								</select>
							</div>
							<div className='content-input'>
								<span className='title'>Distritos</span>
								<select {...setInputProps('district', 'select', form)}>
									<option value='all' selected>
										Todos
									</option>
									<option value='Barranco'>Barranco</option>
									<option value='Cercado de Lima'>Cercado de Lima</option>
									<option value='Chorrillos'>Chorrillos</option>
									<option value='Magdalena'>Magdalena del Mar</option>
									<option value='Pueblo Libre'>Pueblo Libre</option>
									<option value='Santa Beatriz'>Santa Beatriz</option>
									<option value='Jesús María'>Jesús María</option>
								</select>
							</div>
							<div className='content-input'>
								<span className='title'>Dormitorios</span>
								<select {...setInputProps('dorms', 'select dorms', form)}>
									<option value='all' selected>
										Todos
									</option>
									<option value='1'>01 Dorm</option>
									<option value='2'>02 Dorms</option>
									<option value='3'>03 Dorms</option>
								</select>
							</div>
							<div className='content-input'>
								<span className='title'>Vistas</span>
								<select {...setInputProps('vista', 'select vistas', form)}>
									<option value='all' selected>
										Todos
									</option>
									<option value='interior'>Interior</option>
									<option value='exterior'>Exterior</option>
								</select>
							</div>
							<div className='content-input range'>
								<span className='title'>Precios</span>
								<MediaQuery query='(max-width: 499px)'>
									{rangeData.map((item, index) => {
										return (
											<div className='content-radio' key={index + 'range'}>
												<input className='radio-input' type='radio' id={'radio-' + index} name='range_price' value={`${item.value[0]},${item.value[1]}`} />
												<label className='select-radio' for={'radio-' + index}></label>
												<span className='title-price'>{item.title}</span>
											</div>
										);
									})}
								</MediaQuery>
								<MediaQuery query='(min-width: 499px)'>
									{
										<select {...setInputProps('range_price', 'select range', form)}>
											<option value='all' selected>
												Todos
											</option>
											{rangeData.map((item, idx) => {
												return (
													<option value={`${item.value[0]},${item.value[1]}`} key={idx + '-range'}>
														{item.title}
													</option>
												);
											})}
										</select>
									}
								</MediaQuery>
							</div>
						</div>
						<button type='submit' className='btn-submit radius b-red c-white'>
							Buscar
						</button>
					</form>
				);
			}}
		</FormContainer>
	);
};

class SliderCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			percent: 1,
			currentSlide: 0,
			typesSlider: {
				dots: false,
				arrows: false,
				infinite: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				easing: 'linear',
				centerPadding: '10px',
				afterChange: event => {
					this.setState({ percent: event + 1 });
				},
			},
		};
	}
	render() {
		return (
			<div className='content-slider-card'>
				<Slider {...this.state.typesSlider}>
					{this.props.item.typologies.map((card, index) => {
						return <CardStoreComponent card={card} active={this.state.active} project={this.props.item} checked={this.checked} index={index} />;
					})}
				</Slider>
				<div className='progress-container'>
					<progress id='slider-progress' max={this.props.item.typologies.length} value={this.state.percent}></progress>
					<label for='slider-progress'>{`${this.state.percent} / ${this.props.item.typologies.length}`}</label>
				</div>
			</div>
		);
	}
}
class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: false,
			active: false,
			height: '0px',
			innerHtml: '',
			unit: 0,
			card: this.props.card,
		};
		this.list = React.createRef();
		this.item = React.createRef();
	}
	componentDidMount() {
		if (document.querySelector('.content-attr')) {
			$('.content-img').click(function () {
				try {
					document.querySelector('.lg-outer').classList.add('tipologies');
					document.querySelector('.lg').classList.add('tipologies');
				} catch (error) {}
			});
		}
		// console.log('format',this.formatCurrency(2.8))
	}
	checked = card => {
		let check = this.props.cart.tipologiesSelected
			.map(e => {
				return e.id;
			})
			.includes(card.id);
		let btnSeparate = document.querySelector(`#card-${card.id} .separe-depa`);
		if (btnSeparate && check) {
			console.log(btnSeparate);
			btnSeparate.classList.add('active');
		}
		if (btnSeparate && check == false) {
			btnSeparate.classList.remove('active');
		}
		return check;
	};
	formatCurrency = value =>
		value &&
		!isNaN(value.toString().replace(/,/g, '')) &&
		parseFloat(value.toString().replace(/,/g, ''))
			.toFixed(0)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	handleImage() {
		this.setState({ image: true });
	}
	selectUnit = (e, item, index) => {
		console.log(this.props.project);
		let html = document.querySelector(`#${e}`).innerHTML;
		let card = JSON.parse(JSON.stringify(this.props.card));
		card['unit'] = item;
		this.props.updateUnits({ item: item, card: this.props.card });
		this.setState({ innerHtml: html, card: card });
		this.toggle();
		window.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
		window.dataLayer?.push({
			event: 'select_item',
			ecommerce: {
				item_list_id: this.props.project.project_name.toLowerCase(),
				item_list_name: this.props.project.project_name.toLowerCase(),
				items: [
					{
						item_id: this.props.card.id,
						item_name: item.name,
						item_brand: 'Casaideal',
						item_category: this.props.project.project_name.toLowerCase(),
						item_category2: this.props.card.vista,
						item_category3: this.props.card.dormitorio,
						item_category4: item.total_area,
						item_variant: item.name,
						price: item.price,
						quantity: 1,
					},
				],
			},
		});
	};
	toggle = () => {
		let height;
		if (this.state.active == false) {
			height = this.list.current.clientHeight;
		} else {
			height = 0;
		}
		this.setState({ active: !this.state.active, height: `${height}px` });
	};

	addToCart() {
		this.openModal();
		this.initFlat();
		window.dataLayer?.push({ ecommerce: null });
		window.dataLayer?.push({
			event: 'add_to_cart',
			ecommerce: {
				currency: this.state.card?.unit?.currency,
				value: this.props.cart.priceSeparation,
				items: [
					{
						item_id: parseInt(this.state.card?.id),
						item_name: this.state.card?.unit?.type,
						item_brand: 'Casaideal',
						item_category: this.props.project.project_name.toLowerCase(),
						item_category2: this.state.card?.vista,
						item_category3: this.state.card?.dorms,
						item_category4: this.state.card.unit?.total_area,
						item_variant: this.state.card.unit?.name,
						price: this.state.card.unit?.price,
						quantity: 1,
					},
				],
			},
		});
	}

	openModal = () => {
		if (this.state.innerHtml.length < 1) {
			let selectBtn = document.querySelector(`#card-${this.props.card.id} .select-header`);
			if (selectBtn) {
				selectBtn.classList.add('need');
			}
			// this.props.openMessageSelect();
		} else {
			let btnSeparate = document.querySelector(`#card-${this.props.card.id} .separe-depa`);
			if (btnSeparate) {
				btnSeparate.classList.add('active');
			}
			this.props.addCard({ card: this.state.card, project: this.props.project, unit: this.state.unit });
			this.props.openCart();
		}
	};

	initFlat = () => {
		// console.log('card',this.props.card);
	};
	render() {
		return (
			<div className={`card ${this.props.cart.toggleShowCard.includes(this.props.project.page) ? 'hide' : ''}`} id={'card-' + this.props.card.id} style={{ zIndex: 50 - this.props.index }}>
				<div className='content-attr'>
					<div className='header'>
						<div className='type'>{this.props.card.name}</div>
						<div className='dorms semi-bold-poppins b-secondary c-white radius'>{`${this.props.card.dormitorio} ${this.props.card.dormitorio == 1 ? 'Dorm' : 'Dorms'}`}</div>
					</div>
					<div className='content-img'>
						<LightgalleryProvider group='any' key={this.props.card.name}>
							<LightgalleryItem group='any' src={this.props.card.imagen.url}>
								<img className='lupa' src={require('../../Assets/iconos/lupa-tipo.svg').default} href={this.props.card.img} alt='lupa'></img>
							</LightgalleryItem>
						</LightgalleryProvider>
						{this.state.image == false ? <Skeleton /> : null}
						<img
							className='img'
							src={this.props.card.imagen.url}
							onLoad={() => {
								this.handleImage();
							}}
							alt='card'
						></img>
					</div>
					<div className='info'>
						<div
							className={`select-header ${this.state.active == true ? 'active' : ''}`}
							onClick={() => {
								this.toggle();
							}}
						>
							<span className='title-header'>{this.state.innerHtml.length > 1 ? null : 'Selecciona un piso'}</span>
							<div className='inner' dangerouslySetInnerHTML={{ __html: this.state.innerHtml }}></div>
							<div className='icon'>
								<img className='' src={require('../../Assets/images/iconos/down.svg').default}></img>
							</div>
						</div>
						<div className={`price ${this.state.active == true ? 'active' : ''}`} style={{ height: this.state.height }}>
							<div className='content-price' ref={this.list}>
								{this.props.card.units?.length > 0
									? this.props.card.units.map((item, index) => {
											// console.log(item)
											return (
												<div
													className={`prices`}
													id={'item-' + item.name + index}
													ref={this.item}
													key={index + item.name}
													onClick={() => {
														this.selectUnit('item-' + item.name + index, item, index);
													}}
												>
													<span className='name'>{item.name}</span> - 
													<span className='meters'>{item?.total_area}m²</span> - 
													<div className='price-column'>
														<span className='nexa-bold '>S/ {this.formatCurrency(parseInt(item.price) - parseInt(item.price) * 0.03)}</span>
														<span className='c-gray nexa-bold sub-title'>
															antes S/
															<span className='line-thought'>{this.formatCurrency(item.price)}</span>
														</span>
													</div>
												</div>
											);
										})
									: null}
							</div>
						</div>
					</div>
				</div>
				<div
					className={`separe-depa b-red c-white nexa-bold `}
					onClick={() => {
						this.addToCart();
					}}
				>
					<span className='' dangerouslySetInnerHTML={{ __html: `${this.checked(this.props.card) == true ? 'Separado' : `Separar tu depa <span class="bold">S/ ${this.formatCurrency(this.props.cart.priceSeparation)}</span>`}` }}></span>
				</div>
			</div>
		);
	}
}
const MapStateProps = state => {
	return {
		value: state.shopStore,
		dataProjects: state.compactProjects,
		cart: state.cartStore,
	};
};

const CardStoreComponent = connect(MapStateProps, { addCard, openCart, updateUnits, openMessageSelect })(Card);
const SliderStoreComponent = connect(MapStateProps, { addCard, openCart })(SliderCard);

export default connect(MapStateProps, { addCard, deleteCard, modified, openCart, ShowCard })(Shop);
