const unbox = {
	sperant_id: 19,
	route: '/unbox',
	page: 'unbox',
	district: 'Barranco',
	whatsapp: ['924785126'],
	titlehead:"Proyecto Unbox | Departamentos en Barranco",
    metadescription:"Vive en Barranco, en la Av. San Martín, a un paso del Malecón Armendáriz. Departamentos de 2 y 3 dorms desde $214,800 en Entrega Inmediata. Moderno edificio con una arquitectura de tendencia y departamentos bien distribuidos. Cotiza aquí.",
    metakeywords:"departamentos en barranco, departamentos en venta en barranco, inmobiliaria, barranco, casaideal inmobiliaria, departamentos a un paso del malecón armendáriz, departamentos de 2 y 3 dormitorios, edificio en entrega inmediata, departamentos en entrega inmediata",
	iziPubKey: {
		prod: '33403700:publickey_q6keNIUeSlP3VsvF5cou6skBFAvQTPhFdLtuu4bUiMSH5',
		dev: '33403700:testpublickey_xIgRf2LCmnUxHbjfmz9IgmUWamkoUYyINw8EpKEq1UoKj',
	},
	areas: {
		cant: 4,
		comunes: [
			
		],
		interiores: [
		
		],
		exteriores: {
			cantButton: 'avance-none',
			title: 'Avance de obra Agosto',
			desktop: [
				
			],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [
				
			],
		},
		depa_piloto: [
			
		],
		recorrido: 'https://virtualexperience.pe/3d-model/recorrido-virtual-casaideal-proyecto-unbox/fullscreen/',
		avance: {
			cantButton: 'avance-none',
			title: 'Avance de obra Agosto',
			desktop: [],
		},
	},
	form: [
		{ value: 2, title: '2 Dormitorios' },
		{ value: 3, title: '3 Dormitorios' },
	],
	info: {
		title: 'ABRE ',
		subtitle: 'LA CAJA',
		entrega: 'Entrega Inmediata',
		frase: 'A UN PASO DEL <span class="bold">MALECÓN ARMENDÁRIZ</span>',
		pisos: '7',
		depas: '29',
		dorms: '2 y 3 Dorms.',
		ubicacion: 'Av. San Martín N° 508-512, Barranco',
		precio: 'S/773,100',
		desde: '75m²',
		hasta: '99m²',
		fachada: require('../../Assets/images/fachada/Slide/UNBOX.jpg'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/resize/resize-UNBOX.jpg'),
		// ingreso:require("../../Assets/images/fachada/unbox/ingreso.jpg"),
		parrafo: '<p class="paragraph paragraph-1">En un mundo de cajas cerradas, sé quien las abre y cambia las cosas.</p><p class="paragraph paragraph-2">Ubicado en Av. San Martín, Unbox es un edificio moderno con una arquitectura de tendencia y atractivos detalles en porcelanato tipo mármol y cemento expuesto. Departamentos bien distribuidos para familias nuevas y eternos independientes.</p>',
		parrafoArquitectura1: '<span class="paragraph d-block">Cada detalle del proyecto contribuye al ahorro de agua y energía.</span> Mejor distribución de los espacio y mayor ingreso de luz natural.',
		brochure: 'https://casaideal.com.pe/unbox/brochure-unbox.html',
		brochure_mobile: 'https://casaideal.com.pe/unbox/mobile/brochure-unbox.html',
		arquitectura: '<span class="bold br-movil">ARQUITECTURA </span>& DISEÑO',
		mapa: require('../../Assets/mapas/unbox.jpg'),
		sitios: [
			{
				title: 'Supermercados',
				sitio: ['Plaza Vea Cortijo', 'Metro Barranco'],
			},
			{
				title: 'Colegios y Universidades',
				sitio: ['Colegio Los Reyes Rojos', 'Colegio Santa Rosa', 'Colegio Pedro de Osma', 'Cenfotur', 'UAP Arquitectura', 'UTEC'],
			},
			{
				title: 'Cafés & Rest',
				sitio: ['La 73', 'La bodega Verde', 'Isolina', 'Canta Rana'],
			},
			{
				title: 'Iglesias',
				sitio: ['Iglesia Sagrado Corazón de Jesús', 'Iglesia del Nazareno de Barranco'],
			},
			{ title: 'Entretenimiento', sitio: ['MAC', 'M.A.T.E', 'Dedalo'] },
			{
				title: 'Interconexión con',
				sitio: ['Costa Verde', 'Paseo de la república', 'Miraflores'],
			},
		],
	},
	slideDepa: [
		
	],
	atributos: [
		{
			icon: 'edificio',
			text: '<strong>7</strong> Pisos</br><strong>29</strong> Departamentos',
		},
		{
			icon: 'lamp-malecon',
			text: 'Cercano al</br><strong>Malecón Armendáriz</strong>',
		},
		
		{
			icon: 'camara',
			text: '<strong>Cámaras de seguridad</strong></br> en áreas comunes',
		},
		{ icon: 'lobby', text: 'Lobby de </br><strong>doble altura</strong>' },
		{ icon: 'terraza', text: '<strong>Terraza BBQ</strong>' },
		{ icon: 'bebida', text: '<strong>Zona lounge</strong>' },
		{ icon: 'coworking', text: '<strong>Sala Coworking</strong>' },
		{ icon: 'pet', text: '<strong>PetShower</strong>' },
		{icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
	],
	tipologias: {
		page: 'unbox',
		template_id: 90,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/proyectos/logo-unbox.png'),
		cards: [
		],
	},
};

export default unbox;
