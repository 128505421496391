import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ContactoInicio from '../../components/inicio/contacto'
import './beneficios.scss'
export default class Beneficios extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div className="benefits">
                <section>
                    <div className="SliderDepartamentos">
                        <div className='background d-flex'>
                            <picture>
                                <source className="background " media="(min-width: 740px)" srcset={require("../../Assets/images/beneficios/background-beneficios.png")}></source> 
                                <img className='background' src={require("../../Assets/images/beneficios/background-beneficios-movil.png")}></img>
                            </picture>
                        </div>
                        <span className="title-background">BENEFICIOS CASAIDEAL</span>
                    </div>
                </section>
                <section className="cards">
                    <div className="content-cards">

                        <Link to="/beneficios/8-porciento-de-ayuda-en-tu-cuota-inicial">
                            <div className="card">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/beneficios/cards/cuota-inicial.png")}></img>
                                </div>
                                <div className="content-text">
                                    <span>Ayuda en tu Cuota Inicial</span>
                                    <div className="mask icon-right"></div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/beneficios/comprar-hoy-y-paga-a-la-entrega">
                            <div className="card">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/beneficios/cards/regalo.png")}></img>
                                </div>
                                <div className="content-text">
                                    <span>Compra hoy y paga a la entrega</span>
                                    <div className="mask icon-right"></div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/beneficios/Kit-de-cocina-de-regalo">

                            <div className="card">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/beneficios/cards/kit-cocina.png")}></img>
                                </div>
                                <div className="content-text">
                                    <span>Kit de cocina de regalo</span>
                                    <div className="mask icon-right"></div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/beneficios/kit-de-domotica-de-regalo">
                        <div className="card">
                            <div className="content-img">
                                <img src={require("../../Assets/images/beneficios/cards/kit-domatica.png")}></img>
                            </div>
                            <div className="content-text">
                                <span>Kit inteligente de regalo</span>
                                <div className="mask icon-right"></div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/beneficios/campaña-de-referidos">
                            <div className="card">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/beneficios/cards/movil/referidos.png")}></img>
                                </div>
                                <div className="content-text">
                                    <span>Campaña de referidos</span>
                                    <div className="mask icon-right"></div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </section>
                {/* <ContactoInicio page="inicio"></ContactoInicio> */}
            </div>
        )
    }
}
