const stay = {
	sperant_id: 22,
	route: '/stay',
	page: 'stay',
	district: 'Jesús María',
	whatsapp: ['926852305','970459738'],
	titlehead: "Proyecto Stay | Departamentos en Jesús María",
	metadescription:"Vive en la zona más residencial de Jesús María, límite con San Isidro. Departamentos de 1, 2 y 3 dorms desde S/321,300. Stay es un proyecto lleno de inventiva con una arquitectura moderna, una distribución única y excelentes áreas comunes. Cotiza aquí.",
	metakeywords:"departamentos en jesús maría, departamentos en venta en jesús maría, inmobiliaria, jesús maría, casaideal inmobiliaria, departamentos límite con san Isidro, departamentos de 1, 2 y 3 dormitorios, edificio en construcción, departamentos en construcción",
	iziPubKey: {
		prod: '37525957:publickey_rPOnzYTKtaox2xhlCa9Pv6GpQ6C1S50NLJrF9aU7BadjM',
		dev: '37525957:testpublickey_evoeiDJ09UkEIqXNdcnvFwFWUmZQ0wRW6yyePsOR8ABZZ',
	},
	areas: {
		cant: 4,
		comunes: [
			
		],
		interiores: [
		
		],
		exteriores: {
			cantButton: 4,
			title: 'Avance de obra Febrero',
			desktop: [
				
			],
		},
		avance: {
			cantButton:"avance-none",
			title: 'Avance de obra Noviembre',
			desktop:[
                
            ],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [],
		},
		depa_piloto: [
			
		],
		recorrido: 'https://kuula.co/share/collection/7kFmp?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1',
	},
	form: [
		{ value: 1, title: '1 Dormitorios' },
		{ value: 2, title: '2 Dormitorios' },
		{ value: 3, title: '3 Dormitorios' },
	],
	info: {
		title: 'CERCA DE ',
		subtitle: 'TODO',
		entrega: 'En Construcción',
		frase: 'LA ZONA MÁS RESIDENCIAL DE <br> <span class="bold">JESÚS MARÍA LÍMITE CON SAN ISIDRO</span>',
		pisos: '17',
		depas: '64',
		dorms: '1, 2 y 3 Dorms.',
		ubicacion: 'Av. San Felipe N° 537-539, Jesús María',
		precio: 'S/321,300',
		desde: '52m²',
		hasta: '106m²',
		fachada: require('../../Assets/images/fachada/Slide/render_page.png'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/render_page.png'),
		parrafo: '<p class="paragraph">Stay es vivir en un distrito donde todo fluye, donde lo clásico cruza la frontera de la modernidad. Un proyecto lleno de inventiva con una arquitectura moderna y una distribución sinigual.</p><p class="paragraph">Jesús María, un distrito que despierta contigo, que le importa tu vida, tu tiempo y tu familia.</p>',
		parrafoArquitectura1: 'Stay cuenta con una arquitectura y urbanismo que hacen honor al buen gusto, con detalles en ladrillo rococho y perfilería de aluminio color negro.',
		brochure: 'https://casaideal.com.pe/stay/brochure-stay.html',
		brochure_mobile: 'https://casaideal.com.pe/stay/mobile/brochure-stay.html',
		arquitectura: '<span class="bold">ARQUITECTURA </span>& DISEÑO',
		// ubicación
		mapa: require('../../Assets/mapas/mapa-stay.jpg'),
		sitios: [
			{ title: 'Supermercados', sitio: ['Metro', 'Plaza Vea', 'Mass'] },
			{
				title: 'Centros comerciales',
				sitio: ['Real Plaza Salaverry', 'C.C. Risso'],
			},
			{
				title: 'Universidades e institutos',
				sitio: ['UPC', 'Universidad del Pacífico'],
			},
			{ title: 'Colegios', sitio: ['Colegio Los Álamos'] },
			{
				title: 'Entretenimiento',
				sitio: ['Galería de Arte Ryochi Jinnai', 'Plazuela Domingo García Rada', 'Parque Raimondi'],
			},
			{
				title: 'Centros médicos',
				sitio: ['Hospital Rebagliati, Clínica Los Ángeles, Clínica Stella Maris'],
			},
		],
	},
	slideDepa: [
	],
	atributos: [
		{
			icon: 'mask area-chill',
			text: 'Acceso para <strong> fibra óptica </strong>',
		},
		{
			icon: 'sala-de-espera',
			text: '<strong>Lobby &</br> sala de espera</strong>',
		},
		{ icon: 'mask terraza', text: '<strong>Sky pizza & grill</strong>' },
		{ icon: 'mask coffee', text: '<strong>Coffee room & studio</strong>' },
		{
			icon: 'mask area-child',
			text: 'Sala <strong>creativa de niños</strong>',
		},
		{ icon: 'mask gym', text: '<strong>Training center</strong>' },
		{ icon: 'mask game', text: '<strong>Game room</strong>' },
		{ icon: 'mask jardin', text: '<strong>jardín Zen</strong>' },
		{ icon: 'mask bici', text: ' <strong>Bike parking</strong>' },
		{ icon: 'mask pet', text: '<strong>Petshower</strong>' },
		{ icon: 'mask bebida', text: '<strong>Bar lounge</strong>' },
		{icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
		{ icon: 'none desktop', text: '' },
	],
	tipologias: {
		page: 'stay',
		template_id: 93,
		buttons: [
			{ text: '01', value: 1 },
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/iconos/brand-stay.jpeg'),
		cards: [
		],
	},
};
export default stay;
