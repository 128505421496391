import React, { Component } from 'react';
import Contacto from '../components/contacto/contacto';
import Information from '../components/info/information';
import Sliderdeimagenes from '../components/sliderImagenes/sliderdepartamento';
import './template.scss';
import Areas from '../components/areas/areas';
import Mapa from '../components/mapa/mapa';
import Slider from 'react-slick';
import $, { data } from 'jquery';
import Flotante from '../components/flotante/flotante';
import MediaQuery from 'react-responsive'; 
import { ref } from 'yup';
import Filtro from '../components/filtro/filtro';
import Calculator from '../components/calculadora/calculator';
import Data from '../components/data/data';
import QuoterModal from '../components/quoter/quoter';
import { SperantService } from '../components/common/Http/SperantService';
import { connect } from 'react-redux';
import { updateSelected } from '../data/calculator';
import { ProjectHandler } from './store/ProjectHandler';
import { Helmet } from 'react-helmet';
import { ModalProject } from '../components/modalproject/modalprojects';
import { HOST } from '../data/config';
import { configSlider } from './config';
import { Typologies } from './components/tipologies';
import { SectionTypologies } from './components/sectionTypologies/sectionTypologies';

class Templates extends Component {
	constructor(props) {
		super(props);
		this.sperantService = new SperantService();
		this.projectHandler = new ProjectHandler();
		this.skip = this.skip.bind(this);
		this.showQuoter = this.showQuoter.bind(this);
		this.closeQuoter = this.closeQuoter.bind(this);
		this.state = {
			visible: false,
			allCards: [],
			cards: [],
			selectCard: {},
			numDorms: ['all'],
			vista: ['all'],
			type_id: '',
			price_from: '',
			data: {
				imagen: false,
				slider_avance: [],
				banner: [],
				slider_exteriors: [],
                price_from: "",
                metraje: "",
                dorms: "",
				brochure: "",
				url_3d: '',
				slider_avance: [],
				slider_end_project: [],
				slider_areas_comunes: [],
				slider_interiores: [],
				typologies: [],
				slider_end_project: [],
				brochure_mobile: "",
				sliderslider_depa_piloto: [],
			},
			settings: configSlider,
		};
	}
	componentWillUpdate(prevProps) {
		
	}
	async fetching(page) {
		const data = await fetch(`${HOST}/wp-json/custom-api/v1/project?name=${page}`);
		const projects = await data.json();
		return projects;
	}
	async componentDidMount() {
        window.scrollTo(0, 0);
		let data = (await this.fetching(this.props.data.page)) || false;
		
		if (data) {
			const project = await this.projectHandler.formatProject(this.props.data);
			this.setState({ data: data });
		}
		
	}
	showQuoter(type) {
        this.setState({ type_id: type.id, visible: true });
	}

	closeQuoter() {
		this.setState({ visible: false });
	}
	skip(e) {
		e.target.style = 'display:none';
		document.querySelector('.paragraph-2').classList.add('active');
	}
	updateSelectUnit = item => {
		this.props.updateSelectUnit(item);
	};
	render() {
		return (
			<Data.Consumer>
				{context => (
					<main className={`template ${this.props.data.page}`}>
						<Helmet>
							<title>{this.props.data?.titlehead || ''}</title>
							<meta name='description' content={this.props.data?.metadescription || ''}></meta>
							<meta name='keywords' content={this.props.data?.metakeywords || ''}></meta>
						</Helmet>
						<Calculator page={this.props.data.page} url={this.props.data.whatsapp}></Calculator>
						<Flotante project={this.props.data.page} phone={this.props.data.whatsapp}></Flotante>
						<Sliderdeimagenes img={this.state.data.banner} />
						<section id='contacto' className='information container'>
							<div className='info'>
								<span className='handing-over bold'>{this.props.data.info.entrega}</span>
								{this.props.data.page == 'nou' ? (
									<span className='handing-over separe'>
										<span className='spacing'>ÚLTIMO DEPA DESDE</span>
										<span className='bold'> {this.state.data.price_from}</span>
									</span>
								) : (
									<span className='handing-over separe'>
										<span className='spacing'>DEPAS DESDE </span>
										<span className='bold'> {this.state.data.price_from}</span>
									</span>
								)}
								<span className='title' dangerouslySetInnerHTML={{ __html: this.props.data.info.frase }}></span>
								<div className='content-paragraph' dangerouslySetInnerHTML={{ __html: this.props.data.info.parrafo }}></div>
								<span
									className='skip'
									ref={showSkip => (this.showSkip = showSkip)}
									onClick={e => {
										this.skip(e);
									}}
								>
									Seguir leyendo
								</span>
								<p className='paragraph place'>
									<div className={`mask icon-place ${this.props.data.info.class}`}></div>
									<span dangerouslySetInnerHTML={{ __html: this.props.data.info.ubicacion }}></span>
								</p>
								{this.props.data.page == 'nou' ? (
									<p className='paragraph place'>
										<div className='mask rule'></div>
										{this.props.data.info.desde}
									</p>
								) : (
									<p className='paragraph place'>
										<div className='mask rule'></div>
										{this.state.data.metraje}
									</p>
								)}
								<p className='paragraph place'>
									<div className='mask dorm'></div>
									{this.state.data.dorms}
								</p>
								{	
									window.innerWidth > 500 && this.state.data.brochure ? (
										<a href={this.props.data.info.brochure} target='_blank' className='btn-radio outline'>
											Ver brochure
										</a>
									) : null
								}
								{	
									window.innerWidth <= 500 && this.state.data.brochure_mobile ? (
										<a href={this.props.data.info.brochure_mobile} target='_blank' className='btn-radio outline'>
											Ver brochure
										</a>
									) : null
								}
							</div>
							<div className='form' id='formulario'>
								<Contacto project={this.props.data} form={this.props.data.form}></Contacto>
							</div>
						</section>
						<section className='section-atributos'>
							<img className='background-black' src={require('../Assets/images/iconos/background-black.png')}></img>
							<h2 className='title'>
								<span className='bold'>CARACTERÍSTICAS</span> DEL PROYECTO
							</h2>
							<div className='content-atributos'>
								{this.props.data.atributos.map((item, index) => {
									return (
										<div key={'attr' + index} className='item'>
											{this.props.data.page == 'print' && index == 0 ? <img className='icon-edge' src={item.icon}></img> : <div className={'mask ' + item.icon}></div>}
											<span className='text' dangerouslySetInnerHTML={{ __html: item.text }}></span>
										</div>
									);
								})}
							</div>
						</section>
						<section className='description'>
							<div className='container'>
								<img className='render' src={this.props.data.info.fachada}></img>
								{/* <img className="fachada" src={this.props.data.info.ingreso}></img> */}
								<div className='content-info'>
									<h2 className='title' dangerouslySetInnerHTML={{ __html: this.props.data.info.arquitectura }}></h2>
									<p className='paragraph' dangerouslySetInnerHTML={{ __html: this.props.data.info.parrafoArquitectura1 }}></p>
								</div>
							</div>
						</section>
						<Areas areas={this.props.data.areas} page={this.props.data.page} data={this.state.data}></Areas>
						<SectionTypologies 
                            typologies={this.state.data.typologies}
                            showQuoter={this.showQuoter}
                            openModal={context.openCalculator}
                            page={this.props.data.page}
                            ></SectionTypologies>
						<Mapa ubicacion={this.props.data.info} page={this.props.data.page}></Mapa>
						<QuoterModal visible={this.state.visible} actionModalCancel={this.closeQuoter} type_id={this.state.type_id} page={this.props.data.page} template_id={this.props.data.tipologias.template_id} sperant_id={this.props.data.sperant_id} />
					</main>
				)}
			</Data.Consumer>
		);
	}
}
export default connect(null, { updateSelected })(Templates);
