const print = {
    sperant_id: 23,
    route:"/print",
    page:"print",
    district:'Chorrillos',
    whatsapp: ["947302192"],
    titlehead: "Proyecto Print | Departamentos en Chorrillos",
	metadescription:"Vive en la zona más estratégica de Chorrillos. Departamentos de 2 y 3 dorms desde S/304,900. Stay es un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas. Cotiza aquí",
	metakeywords:"departamentos en chorrillos, departamentos en venta en chorrillos, inmobiliaria, chorrillos, casaideal inmobiliaria, departamentos de 2 y 3 dormitorios, edificio en preventa, departamentos en preventa",
    iziPubKey: {
        prod: "70370416:publickey_UdMIVzQjkfnCWXtQGAiwdzvhOsYgQl5PXuKxscQwrDgw2",
        dev: "70370416:testpublickey_2oLGfavT6dAblPP6FPZvPVNU1a4BaqUITImpgIAt4Kp2C",
    },
    areas:{
        cant:4,
        comunes:[

        ],
        interiores:[
        ],
        exteriores:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
                
            ]
        },
        avance:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
            ]
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[]
        },
        depa_piloto:[
        ],
        recorrido:"https://kuula.co/share/collection/7kwkx?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es",
        avance:{
            cantButton:4,
            title:"Avance de obra Noviembre",
            desktop:[
            ]
        },
    },
    form:[
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"CERCA DE ",subtitle:"TODO",
        entrega:'En Construcción',
        frase:'LA ZONA MÁS ESTRATÉGICA <span class="bold">DE CHORRILLOS</span>',
        pisos:"17",depas:"64",dorms:"2 y 3 Dorms.",ubicacion:"Calle Ontario 200, La Campiña, Chorrillos",precio:"S/304,900",desde:"57m²",hasta:"65m²",
        fachada:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        fachadaMinified:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        parrafo:`
            <p class="paragraph">Un cambio que tú y tu familia necesitan, encuentra en Print la exclusividad y calidad que mereces en un barrio accesible.</p>
            <p class="paragraph">Un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas.</p>
            <p class="paragraph">Hacer de la cotidianidad un espacio de libertad y de tu barrio, una extensión de tu hogar.</p>
            `,
        parrafoArquitectura1:'Print es un condominio que cuenta con 3 torres y una arquitectura elegante con detalles en concreto caravista.',
        brochure:'https://casaideal.com.pe/print/brochure-print.html',
        brochure_mobile:'https://casaideal.com.pe/print/mobile/brochure-print.html',
        arquitectura:'<span class="bold">ARQUITECTURA </span>& DISEÑO',
        // ubicación
        mapa:require("../../Assets/mapas/print.jpg"),
        sitios:[
            {title:"Centro comercial, mercados:",sitio:["Real plaza guardia civil","Metro","Mercado santa rosa","Precio uno ","Plaza Lima Sur"]},
            {title:"Bancos:",sitio:["BBVA","Plaza Vea","Cajero banco de la nación","Cajero bcp","Caja huancayo","Agente scotiabank"]},
            {title:"Salud::",sitio:["Policlínico Essalud Juan José Rodriguez","Inkafarma"]},
            {title:"Estudios:",sitio:["Escuela pnp"]},
           
        ]
    },
    slideDepa:[
           
    ],
    atributos:[
        // {icon:"mask edificio",text:"<strong>Departamentos inteligentes</strong>"},
       
        {icon:require("../../Assets/images/iconos/edge.svg").default,text:"Certificación</br> Edge"},
        {icon:"mask area-chill",text:"Acceso para <strong> fibra óptica </strong>"},
        {icon:"sala-de-espera",text:"<strong>Lobby &</br> sala de espera</strong>"},
        {icon:"mask flower",text:"<strong>Alameda Interior</strong>"},
        {icon:"mask sky-bar",text:"<strong>Sala Bar</strong>"},
        {icon:"mask parrilla",text:"Zona <strong>BBQ</strong>"},
        {icon:"relax",text:"Zona <strong>Relax</strong>"},
        {icon:"mask piscina",text:"<strong>Piscina</strong>"},
        {icon:"mask coworking",text:"Sala <strong>Coworking</strong>"},
        {icon:"mask area-child",text:" <strong>Sala de niños</strong>"},
        {icon:"mask gym",text:"<strong>Zona Fit</strong>"},
        {icon:"mask bici",text:"Estacionamiento de </br><strong>bicicletas</strong>"},
        {icon:"mask lavado",text:"Zona de <strong>Lavado</strong>"},
        {icon:"mask pet",text:"Zona de <strong>Mascotas</strong>"},
        {icon:"gas",text:`<span class="text" style="text-wrap: balance;display:flex;flex-direction: column;">Redes internas de Gas Natural <br><span style="font-weight: 100;font-size: .7rem;opacity: .8;">*Sujeto a conexión de Cálidda</span></span>`},
    ],
    tipologias:{
        page:"print",
        template_id: 106,
        buttons:[{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/proyectos/brand-print.svg').default,
        cards: [
        ],
       
    }
}
export default print